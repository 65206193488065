import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'status', 'title', 'description' ]

  connect() {
    this.populate_title_and_description();
  }

  populate_title_and_description() {
    $.get('/get_nice_status', { status: this.statusTarget.value }, data => {
      this.titleTarget.value = data.title;
      this.descriptionTarget.value = data.desc;
    });
  }
}
