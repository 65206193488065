import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "animate" ]

  copy() {
    $(this.sourceTarget).append(`<input id="url_to_copy" value="${this.sourceTarget.href}">`);
    $('#url_to_copy').select();
    document.execCommand("copy")
    $('#url_to_copy').remove();
    $(this.animateTarget).hide().fadeIn(500);
  }
}
