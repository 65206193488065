import { Controller } from "stimulus"

export default class extends Controller {

  toggle() {
    $(this.element).next('.item__actions').toggleClass('in');
  }

  close() {
    $(this.element).parent('.item__actions').toggleClass('in');
  }

}
