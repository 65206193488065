import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
     $(this.element).select2({
       ajax: {
         url: '/users',
         dataType: 'json',
         delay: 500
       },
       minimumInputLength: 3
     });
  }
}
