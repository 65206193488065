import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    $(this.element).find('input:file').fileupload({
      dataType: 'script',
      dropZone: this.element,
      progressall: (e, data) => {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        $(this.element).find('.progress-bar').css(
          'width',
          progress + '%'
        );
      }
    });
  }
}
