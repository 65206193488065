import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    $(this.element).on('refresh_content', () => {
      this.load();
    });

  }

  load() {
    $.get(this.data.get('url'), {}, partial => {
      if (this.hasContentTarget) {
        this.contentTarget.innerHTML = partial;
      }
      else {
        this.element.innerHTML = partial;
      }
      $(document).trigger('content_loader_finished');
    });
  }
}
